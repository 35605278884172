import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ResponsiveImage from "./Img/ResponsiveImage";

const Footer = () => {
  const { i18n, t } = useTranslation();
  const date = new Date();
  const year = date.getFullYear();
  return (
    <FooterDiv>
      <div>
        <div>
          <a href="/privacy-policy" rel="nofollow">
            {t("footer-a-o")}
          </a>
          <a href="/terms-of-use" rel="nofollow">
            {t("footer-a-t")}
          </a>
          <a
            href="https://sendy.playground.ca/subscription?f=bJP1wJRJSHeeook12I5763qzrLmjDc4owvP9VnD5hYBKwSdB9HlUOtvofTqZEZsB1Y"
            target="__blank"
            rel="nofollow"
          >
            {t("footer-a-three")}
          </a>
        </div>
      </div>
      {i18n.language === "en" ? (
        <div>&#169; {year} Drunken Dragon®. All right reserved.</div>
      ) : (
        <div>&#169; {year} Drunken Dragon®. Tous droits réservés.</div>
      )}
      <div>
        <a href="https://www.facebook.com/drunkendragonmtl" target="__blank">
          <ResponsiveImage
            src={"icons/facebook-circular-logo.svg"}
            alt="facebook-icon"
          />
        </a>
        <a
          href="https://www.instagram.com/drunkendragon.resto/"
          target="__blank"
        >
          <ResponsiveImage src={"icons/instagram.svg"} alt="instagram-icon" />
        </a>
        <a href="https://twitter.com/_drunkendragon" target="__blank">
          <ResponsiveImage src={"icons/x.svg"} alt="twitter-icon" />
        </a>
        <a href="https://www.tiktok.com/@drunken.dragon" target="__blank">
          <ResponsiveImage src={"icons/tik-tok.svg"} alt="tiktok-icon" />
        </a>
      </div>
    </FooterDiv>
  );
};
const FooterDiv = styled.div`
  position: relative;
  bottom: 0;
  left: 0;
  height: auto;
  /* min-height: 150px; */
  width: 100%;
  overflow-x: hidden;
  background: var(--black);
  color: var(--silver);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px 20px 207px;
  gap: 40px;
  z-index: 9;
  & > div {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    & > div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      text-align: center;
      & > a {
        display: flex;
        padding: 0 10px;
        opacity: 0.7;
        &:hover {
          transition: 0.1s all;
          opacity: 1;
        }
      }
      & > a:nth-child(1),
      & > a:nth-child(2) {
        padding: 0 10px;
        border-right: 1px solid var(--silver);
      }
      & > a:nth-child(1) {
        padding-left: 0;
      }
    }
    & > a {
      & > img {
        width: 24px;
        height: 24px;
        object-fit: contain;
        opacity: 0.7;
        &:hover {
          transition: 0.1s all;
          opacity: 1;
        }
      }
    }
    & > p {
      margin-bottom: 10px;
      font-size: 16px;
      line-height: 30px;
      font-weight: 300;
      &:hover {
        display: none;
        color: var(--gold_logo);
      }
    }
  }
  & > div:first-child {
    justify-content: flex-start;
    & > div {
      /* white-space: nowrap; */
    }
  }
  & > div:nth-child(2) {
    opacity: 0.7;
  }
  & > div:last-child {
    justify-content: flex-end;
  }
  @media screen and (max-width: 1240px) {
    /* start of phone and medium tablet styles */
    flex-direction: column;
    padding: 40px 20px 120px 20px;
    & > div {
      flex-direction: column;
      text-align: center;
      justify-content: center;
      width: 100%;
      & > div {
        flex-direction: column;
        gap: 20px;
      }
    }
    & > div:first-child {
      & > div {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        & > a {
          border: none;
        }
      }
    }
    & > div:last-child {
      flex-direction: row;
      justify-content: center;
    }
  }
`;
export default Footer;
