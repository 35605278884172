import React, { memo } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

const breakpoints = {
  xxs: 0,
  xs: 320,
  sm: 640,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1500,
};

const IMAGE_CDN_BASE_URL = "https://assets.playground.ca/orochi/img/";

const imageSizes = {
  xxs: "320x320",
  xs: "320x320",
  sm: "640x640",
  md: "768x768",
  lg: "992x992",
  xl: "1200x1200",
  xxl: "1600x1600",
};

const getImageUrl = (image, size) =>
  image ? `${IMAGE_CDN_BASE_URL}fit-in/${size}/${image}` : "";

const ResponsiveImage = memo(
  ({ src, alt, sizes, customStyles, ...props }) => {
    const imageSrcSet = Object.keys(breakpoints)
      .map((key) => `${getImageUrl(src, imageSizes[key])} ${breakpoints[key]}w`)
      .join(", ");

    return (
      <StyledImg
        src={getImageUrl(src, imageSizes.xxs)}
        srcSet={imageSrcSet}
        sizes={sizes}
        alt={alt}
        customStyles={customStyles}
        {...props}
      />
    );
  }
);

ResponsiveImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  sizes: PropTypes.string,
  customStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

ResponsiveImage.defaultProps = {
  sizes: "(max-width: 991px) 100vw, 100vw",
  customStyles: {},
};

const StyledImg = styled.img`
  width: ${(props) => props.customStyles.width || "auto"};
  height: ${(props) => props.customStyles.height || "auto"};
  display: block;
  ${(props) => props.customStyles && css`${props.customStyles}`};
  @media (min-width: ${breakpoints.xs}px) {
    max-width: 100%;
  }
  @media (min-width: ${breakpoints.sm}px) {
    max-width: 100%;
  }
  @media (min-width: ${breakpoints.md}px) {
    max-width: 100%;
  }
  @media (min-width: ${breakpoints.lg}px) {
    max-width: 100%;
  }
  @media (min-width: ${breakpoints.xl}px) {
    max-width: 100%;
  }
  @media (min-width: ${breakpoints.xxl}px) {
    max-width: 100%;
  }
`;

export default ResponsiveImage;
