const breakpoints = {
  sm: 991,  // breakpoint for smaller screens
  lg: 992, // breakpoint for larger screens
};

const IMAGE_CDN_BASE_URL = "https://assets.playground.ca/orochi/img/";

const imageSizes = {
  sm: "1125x1125",
  lg: "1920x1920",
};

const getImageSizeForWidth = (width) => {
  if (width >= breakpoints.lg) return imageSizes.lg;
  return imageSizes.sm;
};

const getResponsiveImageUrl = (image) => {
  if (!image) return "";

  const width = window.innerWidth;
  const size = getImageSizeForWidth(width);
  return `${IMAGE_CDN_BASE_URL}fit-in/${size}/${image}`;
};

export default getResponsiveImageUrl;
